import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { usePopperTooltip } from "react-popper-tooltip";
import clsx from "clsx";
import Typed from "react-typed";
import { Link as ScrollLink } from "react-scroll";
import { AnimatePresence, motion } from "framer-motion";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import "./style.scss";
import Typography from "../Typography";
import logoVideoMP4 from "../../images/linear-logo-animation.mp4";
import Card from "../Card";
import CardBody from "../CardBody";
import Icon from "../Icon";
import AlogoPhone from "../Alogo/Phone/AlogoPhone";
import useLayoutHook from "../../hooks/useLayoutHook";

const HomeBanner = ({ heading, underline, scrollDownLabel, buttons, alogoprops }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  const phone = useLayoutHook();
  return (
    <div style={{ position: "relative" }}>
      <section className="container home-banner">
        <div className="home-banner-text">
          <Typography variant="tittle-1" color="white" component="div" className="mb-20">
            {heading}
          </Typography>
          <Typography variant="introduction-1" className="mb-48">
            {underline?.text}
          </Typography>
          <div
            style={{
              marginTop: "35px",
            }}
          >
            {(buttons ?? []).map((button, index) => {
              if (button?.external) {
                return (
                  <a
                    key={button?.to}
                    href={button?.to}
                    title={button?.title}
                    className={clsx(
                      "btn btn-size-3 rounded-pill align-items-end mr-8 ",
                      `btn-${button.color ?? "primary"}`
                    )}
                    target={button?.newWindow ? "_blank" : null}
                    rel={button?.newWindow ? "noreferrer" : null}
                  >
                    <span className="btn-label">{button?.label}</span>
                  </a>
                );
              }

              return (
                <Link
                  key={button?.to}
                  to={button?.to}
                  title={button?.title}
                  className={clsx(
                    "btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8",
                    `btn-${button.color ?? "primary"}`
                  )}
                  target={button?.newWindow ? "_blank" : null}
                  rel={button?.newWindow ? "noreferrer" : null}
                >
                  <span
                    className="btn-overlay"
                    style={{ width: button?.label?.length * 10 + 60, height: button?.label?.length * 10 + 60 }}
                  />
                  <span className="btn-label">{button?.label}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="home-banner-logo">
          {/* <video autoPlay muted disableRemotePlayback playsInline loop>
            <source src={logoVideoMP4} type="video/mp4" />
          </video> */}
          {phone ? <AlogoPhone colour={0} /> : ""}
        </div>
      </section>
    </div>
  );
};

HomeBanner.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.array,
  underline: PropTypes.object,
  scrollDownLabel: PropTypes.string,
};

HomeBanner.defaultProps = {
  heading: "The first cross-chain compatible, delta-one asset protocol.",
  subtitle:
    "linear is the first of its kind trading protocol that enables trading of liquid assets seamlessly and effectively.",
  buttons: [],
  underline: {},
  scrollDownLabel: "Scroll down to learn more",
};

export default HomeBanner;
