import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as TWEEN from "./tween.esm.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";

export default (container, scrollProgresses) => {
  const scene = new THREE.Scene();
  const mouse = new THREE.Vector2(0, 0);
  const target = new THREE.Vector3(0, 0, 0);

  const width = window.innerWidth;
  const height = () => {
    return Math.min(window.innerHeight, 1440);
  };

  const camera = new THREE.PerspectiveCamera(50, width / height(), 0.01, 1000);
  camera.position.set(0, 0, 600);
  camera.rotation.set(0, 0, 0);

  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

  renderer.setSize(width, height());
  renderer.toneMapping = THREE.ReinhardToneMapping;
  renderer.setClearColor(0x000000, 0);
  container.appendChild(renderer.domElement);

  const textureLoader = new THREE.TextureLoader();
  const matcapTexture = textureLoader.load("/matcap/matcap.jpeg");

  const composer = new EffectComposer(renderer);
  const renderPass = new RenderPass(scene, camera);
  composer.addPass(renderPass);

  const materialBottom = new THREE.MeshStandardMaterial({
    color: 0x0022ff, // Base color of the material
    roughness: 0.3, // Adjust the roughness to control reflection
    metalness: 0.8, // Set to 1 for full reflection
    envMap: matcapTexture,
  });
  const materialMiddle = new THREE.MeshStandardMaterial({
    color: 0x3a60fc, // Base color of the material
    roughness: 0.3, // Adjust the roughness to control reflection
    metalness: 0.8, // Set to 1 for full reflection
    envMap: matcapTexture,
  });
  const materialTop = new THREE.MeshStandardMaterial({
    color: 0x7db5ff, // Base color of the material
    roughness: 0.3, // Adjust the roughness to control reflection
    metalness: 0.8, // Set to 1 for full reflection
    envMap: matcapTexture,
  });

  const mainLight = new THREE.DirectionalLight(0xffffff);
  mainLight.position.add(new THREE.Vector3(0, 100, 100));
  mainLight.intensity = 10;
  scene.add(mainLight);

  const spotLight = new THREE.SpotLight(0xffffff);
  spotLight.position.add(new THREE.Vector3(235, 0, 350));
  spotLight.rotation.copy(new THREE.Euler(0, 0, 0));
  spotLight.intensity = 0.65;
  spotLight.decay = 1;
  spotLight.angle = 2;
  scene.add(spotLight);

  const loader = new GLTFLoader();

  const shrinkTime = 0.25 * 1000;
  const growTime = 0.25 * 1000;
  const delayTop = 0;
  const delayMiddle = 0.1 * 1000;
  const delayBottom = 0.2 * 1000;

  let gltfModel;
  let linearLogo;
  let topShape;
  let topShapeMesh;
  let middleShape;
  let middleShapeMesh;
  let bottomShape;
  let bottomShapeMesh;
  let currentBoundary;

  function copyKeyFrame(keyFrameToCopy) {
    return {
      position: new THREE.Vector3(keyFrameToCopy.position.x, keyFrameToCopy.position.y, keyFrameToCopy.position.z),
      rotation: new THREE.Euler(keyFrameToCopy.rotation.x, keyFrameToCopy.rotation.y, keyFrameToCopy.rotation.z),
      scale: new THREE.Vector3(keyFrameToCopy.scale.x, keyFrameToCopy.scale.y, keyFrameToCopy.scale.z),
      color:
        keyFrameToCopy.color === undefined
          ? new THREE.Color(0xffffff)
          : new THREE.Color(keyFrameToCopy.color.r, keyFrameToCopy.color.g, keyFrameToCopy.color.b),
    };
  }

  let linearLogoKeyFrameDefault = {
    position: new THREE.Vector3(0, 0, 0),
    rotation: new THREE.Euler(0, -0.436332, 0),
    scale: new THREE.Vector3(1.4, 1.4, 1.4),
  };

  let topShapeKeyFrameDefault = {
    position: new THREE.Vector3(-21.92, 21.93, 10.04),
    rotation: new THREE.Euler(0, 0, Math.PI / 4),
    scale: new THREE.Vector3(1, 1, 1),
    color: new THREE.Color(0x7db5ff),
  };

  let topShapeKeyFrameOffScreen = copyKeyFrame(topShapeKeyFrameDefault);
  topShapeKeyFrameOffScreen.position.x += 350;
  topShapeKeyFrameOffScreen.position.y += 350;

  let topShapeKeyFrameRotated = copyKeyFrame(topShapeKeyFrameDefault);
  topShapeKeyFrameRotated.rotation.y -= Math.PI;
  topShapeKeyFrameRotated.rotation.x += Math.PI;

  let topShapeMinimizedDefault = copyKeyFrame(topShapeKeyFrameRotated);
  topShapeMinimizedDefault.scale.x = 0;
  topShapeMinimizedDefault.scale.y = 0;
  topShapeMinimizedDefault.scale.z = 0;
  topShapeMinimizedDefault.rotation.y -= Math.PI;
  topShapeMinimizedDefault.rotation.x += Math.PI;

  let topShapeKeyFrameRegrownLightBlue = copyKeyFrame(topShapeMinimizedDefault);
  topShapeKeyFrameRegrownLightBlue.scale.x = 1;
  topShapeKeyFrameRegrownLightBlue.scale.y = 1;
  topShapeKeyFrameRegrownLightBlue.scale.z = 1;
  topShapeKeyFrameRegrownLightBlue.rotation.y += Math.PI;
  topShapeKeyFrameRegrownLightBlue.rotation.x -= Math.PI;
  topShapeKeyFrameRegrownLightBlue.color = new THREE.Color(0x7db5ff);

  let topShapeKeyFrameRegrownMidBlue = copyKeyFrame(topShapeMinimizedDefault);
  topShapeKeyFrameRegrownMidBlue.scale.x = 1;
  topShapeKeyFrameRegrownMidBlue.scale.y = 1;
  topShapeKeyFrameRegrownMidBlue.scale.z = 1;
  topShapeKeyFrameRegrownMidBlue.rotation.y += Math.PI;
  topShapeKeyFrameRegrownMidBlue.rotation.x -= Math.PI;
  topShapeKeyFrameRegrownMidBlue.color = new THREE.Color(0x3a60fc);

  let topShapeKeyFrameRegrownDarkBlue = copyKeyFrame(topShapeMinimizedDefault);
  topShapeKeyFrameRegrownDarkBlue.scale.x = 1;
  topShapeKeyFrameRegrownDarkBlue.scale.y = 1;
  topShapeKeyFrameRegrownDarkBlue.scale.z = 1;
  topShapeKeyFrameRegrownDarkBlue.rotation.y += Math.PI;
  topShapeKeyFrameRegrownDarkBlue.rotation.x -= Math.PI;
  topShapeKeyFrameRegrownDarkBlue.color = new THREE.Color(0x0022ff);

  let middleShapeKeyFrameDefault = {
    position: new THREE.Vector3(0.41, 0.6, 10.04),
    rotation: new THREE.Euler(0, 0, Math.PI / 4),
    scale: new THREE.Vector3(1, 1, 1),
    color: new THREE.Color(0x3a60fc),
  };

  let middleShapeKeyFrameOffScreen = copyKeyFrame(middleShapeKeyFrameDefault);
  middleShapeKeyFrameOffScreen.position.x += 350;
  middleShapeKeyFrameOffScreen.position.y += 350;

  let middleShapeKeyFrameRotated = copyKeyFrame(middleShapeKeyFrameDefault);
  middleShapeKeyFrameRotated.rotation.y -= Math.PI;
  middleShapeKeyFrameRotated.rotation.x += Math.PI;

  let middleShapeMinimizedDefault = copyKeyFrame(middleShapeKeyFrameRotated);
  middleShapeMinimizedDefault.scale.x = 0;
  middleShapeMinimizedDefault.scale.y = 0;
  middleShapeMinimizedDefault.scale.z = 0;
  middleShapeMinimizedDefault.rotation.y -= Math.PI;
  middleShapeMinimizedDefault.rotation.x += Math.PI;

  let middleShapeKeyFrameRegrownLightBlue = copyKeyFrame(middleShapeMinimizedDefault);
  middleShapeKeyFrameRegrownLightBlue.scale.x = 1;
  middleShapeKeyFrameRegrownLightBlue.scale.y = 1;
  middleShapeKeyFrameRegrownLightBlue.scale.z = 1;
  middleShapeKeyFrameRegrownLightBlue.rotation.y += Math.PI;
  middleShapeKeyFrameRegrownLightBlue.rotation.x -= Math.PI;
  middleShapeKeyFrameRegrownLightBlue.color = new THREE.Color(0x7db5ff);

  let middleShapeKeyFrameRegrownMidBlue = copyKeyFrame(middleShapeMinimizedDefault);
  middleShapeKeyFrameRegrownMidBlue.scale.x = 1;
  middleShapeKeyFrameRegrownMidBlue.scale.y = 1;
  middleShapeKeyFrameRegrownMidBlue.scale.z = 1;
  middleShapeKeyFrameRegrownMidBlue.rotation.y += Math.PI;
  middleShapeKeyFrameRegrownMidBlue.rotation.x -= Math.PI;
  middleShapeKeyFrameRegrownMidBlue.color = new THREE.Color(0x3a60fc);

  let middleShapeKeyFrameRegrownDarkBlue = copyKeyFrame(middleShapeMinimizedDefault);
  middleShapeKeyFrameRegrownDarkBlue.scale.x = 1;
  middleShapeKeyFrameRegrownDarkBlue.scale.y = 1;
  middleShapeKeyFrameRegrownDarkBlue.scale.z = 1;
  middleShapeKeyFrameRegrownDarkBlue.rotation.y += Math.PI;
  middleShapeKeyFrameRegrownDarkBlue.rotation.x -= Math.PI;
  middleShapeKeyFrameRegrownDarkBlue.color = new THREE.Color(0x0022ff);

  let bottomShapeKeyFrameDefault = {
    position: new THREE.Vector3(21.75, -21.07, 10.04),
    rotation: new THREE.Euler(0, 0, Math.PI / 4),
    scale: new THREE.Vector3(1, 1, 1),
    color: new THREE.Color(0x0022ff),
  };

  let bottomShapeKeyFrameOffScreen = copyKeyFrame(bottomShapeKeyFrameDefault);
  bottomShapeKeyFrameOffScreen.position.x += 350;
  bottomShapeKeyFrameOffScreen.position.y += 350;

  let bottomShapeKeyFrameRotated = copyKeyFrame(bottomShapeKeyFrameDefault);
  bottomShapeKeyFrameRotated.rotation.y -= Math.PI;
  bottomShapeKeyFrameRotated.rotation.x += Math.PI;

  let bottomShapeMinimizedDefault = copyKeyFrame(bottomShapeKeyFrameRotated);
  bottomShapeMinimizedDefault.scale.x = 0;
  bottomShapeMinimizedDefault.scale.y = 0;
  bottomShapeMinimizedDefault.scale.z = 0;
  bottomShapeMinimizedDefault.rotation.y -= Math.PI;
  bottomShapeMinimizedDefault.rotation.x += Math.PI;

  let bottomShapeKeyFrameRegrownLightBlue = copyKeyFrame(bottomShapeMinimizedDefault);
  bottomShapeKeyFrameRegrownLightBlue.scale.x = 1;
  bottomShapeKeyFrameRegrownLightBlue.scale.y = 1;
  bottomShapeKeyFrameRegrownLightBlue.scale.z = 1;
  bottomShapeKeyFrameRegrownLightBlue.rotation.y += Math.PI;
  bottomShapeKeyFrameRegrownLightBlue.rotation.x -= Math.PI;
  bottomShapeKeyFrameRegrownLightBlue.color = new THREE.Color(0x7db5ff);

  let bottomShapeKeyFrameRegrownMidBlue = copyKeyFrame(bottomShapeMinimizedDefault);
  bottomShapeKeyFrameRegrownMidBlue.scale.x = 1;
  bottomShapeKeyFrameRegrownMidBlue.scale.y = 1;
  bottomShapeKeyFrameRegrownMidBlue.scale.z = 1;
  bottomShapeKeyFrameRegrownMidBlue.rotation.y += Math.PI;
  bottomShapeKeyFrameRegrownMidBlue.rotation.x -= Math.PI;
  bottomShapeKeyFrameRegrownMidBlue.color = new THREE.Color(0x3a60fc);

  let bottomShapeKeyFrameRegrownDarkBlue = copyKeyFrame(middleShapeMinimizedDefault);
  bottomShapeKeyFrameRegrownDarkBlue.scale.x = 1;
  bottomShapeKeyFrameRegrownDarkBlue.scale.y = 1;
  bottomShapeKeyFrameRegrownDarkBlue.scale.z = 1;
  bottomShapeKeyFrameRegrownDarkBlue.rotation.y += Math.PI;
  bottomShapeKeyFrameRegrownDarkBlue.rotation.x -= Math.PI;
  bottomShapeKeyFrameRegrownDarkBlue.color = new THREE.Color(0x0022ff);

  const initialSlideTweenAnimations = [];

  function adjustSceneAndCamera() {
    camera.aspect = window.innerWidth / height();
    // camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, height());
    composer.setSize(window.innerWidth, height());
  }

  window.addEventListener("resize", () => {
    adjustSceneAndCamera();
  });

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  document.body.onscroll = () => {
    calculateIfAnimationsNeedToBePerformed();
  };

  document.addEventListener("mousemove", (event) => {
    mouse.y = event.clientY - window.innerHeight / 2;
  });

  loader.load(
    "/glft/linear_logo.gltf",
    function (gltf) {
      gltfModel = gltf.scene;
      if (gltfModel) {
        linearLogo = gltfModel.getObjectByName("Linear");
        topShape = gltfModel.getObjectByName("TopLine");
        topShape.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            topShapeMesh = child;
            topShapeMesh.material = materialTop;
          }
        });
        middleShape = gltfModel.getObjectByName("MiddleLine");
        middleShape.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            middleShapeMesh = child;
            middleShapeMesh.material = materialMiddle;
          }
        });
        bottomShape = gltfModel.getObjectByName("BottomLine");
        bottomShape.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            bottomShapeMesh = child;
            bottomShapeMesh.material = materialBottom;
          }
        });
        scene.add(gltfModel);
        setInitialState();
        slideInFromSide();
      }
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );

  const cleanUp = () => {
    // Remove the renderer's DOM element
    container.removeChild(renderer.domElement);

    // Dispose of the renderer and any other resources
    renderer.dispose();

    // Clean up any global event listeners
    window.removeEventListener("resize", adjustSceneAndCamera);
  };

  function lookAtCursor() {
    let y = linearLogo.rotation.y;
    target.y += (-mouse.y / 3 - target.y) * 0.02;
    target.z = camera.position.z;
    linearLogo.lookAt(new THREE.Vector3(y, target.y, target.z));
    linearLogo.rotation.y = y + 0.005;
  }

  // Your animation loop
  const animate = (time) => {
    requestAnimationFrame(animate);
    if (gltfModel) {
      TWEEN.update(time);
      lookAtCursor();
    }
    composer.render();
  };

  function calculateIfAnimationsNeedToBePerformed() {
    scrollProgresses.forEach((animationTriggers) => {
      if (animationTriggers.active) {
        if (animationTriggers.name === "linearLightBlue" && animationTriggers.scrollProgress <= 0.05) {
          if (currentBoundary != "notScrolled") {
            currentBoundary = "notScrolled";
            performAnimationOfBoundary(currentBoundary);
          }
        }
        if (0.85 <= animationTriggers.scrollProgress && animationTriggers.scrollProgress <= 1) {
          // If the boundary has changed, perform the animations tied to that change
          if (currentBoundary != animationTriggers.name) {
            currentBoundary = animationTriggers.name;
            performAnimationOfBoundary(currentBoundary);
          }
          return;
        }
      }
    });
  }

  function performAnimationOfBoundary(boundary) {
    TWEEN.removeAll();
    switch (boundary) {
      case "notScrolled":
        animateToStart();
        break;
      case "linearDarkBlue":
        animateSwapToDarkBlue();
        break;
      case "linearLightBlue":
        animateSwapToLightBlue();
        break;
      case "linearMidBlue":
        animateSwapToMidBlue();
        break;
      case "linearCenter":
        animateFinal();
        break;
    }
  }

  function generateAnimations(
    endKeyFrame,
    duration,
    delay,
    model,
    material,
    log,
    modelName = "-",
    transitionName = "-"
  ) {
    var listOfAnimations = [];
    var initialState = copyKeyFrame(model);
    listOfAnimations.push(
      new TWEEN.Tween(model.rotation)
        .to(
          {
            x: endKeyFrame.rotation.x,
            y: endKeyFrame.rotation.y,
            z: endKeyFrame.rotation.z,
          },
          duration
        )
        .delay(delay)
        .onComplete(() => {
          if (log) {
            console.log(
              `Completed rotation ${modelName} ${transitionName}: ${initialState.rotation.x} -> ${endKeyFrame.rotation.x}, ${initialState.rotation.y} -> ${endKeyFrame.rotation.y}, ${initialState.rotation.z} -> ${endKeyFrame.rotation.z}`
            );
          }
        })
    );
    listOfAnimations.push(
      new TWEEN.Tween(model.scale)
        .to(
          {
            x: endKeyFrame.scale.x,
            y: endKeyFrame.scale.y,
            z: endKeyFrame.scale.z,
          },
          duration
        )
        .delay(delay)
        .onComplete(() => {
          if (log) {
            console.log(
              `Completed scale ${modelName} ${transitionName}: ${initialState.scale.x} -> ${endKeyFrame.scale.x}, ${initialState.scale.y} -> ${endKeyFrame.scale.y}, ${initialState.scale.z} -> ${endKeyFrame.scale.z}`
            );
          }
        })
    );
    if (material != null) {
      listOfAnimations.push(
        new TWEEN.Tween(material.color)
          .to(
            {
              b: endKeyFrame.color.b,
              g: endKeyFrame.color.g,
              r: endKeyFrame.color.r,
            },
            duration
          )
          .delay(delay)
          .onComplete(() => {
            if (log) {
              console.log(
                `Completed color ${modelName} ${transitionName}: ${initialState.color.r} -> ${endKeyFrame.color.r}, ${initialState.color.g} -> ${endKeyFrame.color.g}, ${initialState.color.b} -> ${endKeyFrame.color.b}`
              );
            }
          })
      );
    }
    return listOfAnimations;
  }

  function animateToStart() {
    container.style.filter = "blur(0px)";
    var topAnimations = generateAnimations(
      topShapeKeyFrameDefault,
      shrinkTime,
      delayTop,
      topShape,
      materialTop,
      false,
      "top logo",
      "to start"
    );
    topAnimations.forEach((animation) => {
      animation.start();
    });
    var middleAnimations = generateAnimations(
      middleShapeKeyFrameDefault,
      growTime,
      delayMiddle,
      middleShape,
      materialMiddle,
      false,
      "middle logo",
      "to start"
    );
    middleAnimations.forEach((animation) => {
      animation.start();
    });
    var bottomAnimations = generateAnimations(
      bottomShapeKeyFrameDefault,
      growTime,
      delayBottom,
      bottomShape,
      materialBottom,
      false,
      "bottom logo",
      "to start"
    );
    bottomAnimations.forEach((animation) => {
      animation.start();
    });
  }
  function animateSwapToLightBlue() {
    container.style.filter = "blur(0px)";
    var topAnimations = generateAnimations(
      topShapeMinimizedDefault,
      shrinkTime,
      delayTop,
      topShape,
      materialTop,
      false,
      "top logo",
      "to LightBlue part 1"
    );
    topAnimations[0].chain(
      ...generateAnimations(
        topShapeKeyFrameRegrownLightBlue,
        growTime,
        0,
        topShape,
        materialTop,
        false,
        "top logo",
        "to LightBlue part 2"
      )
    );
    topAnimations.forEach((animation) => {
      animation.start();
    });
    var middleAnimations = generateAnimations(
      middleShapeMinimizedDefault,
      shrinkTime,
      delayMiddle,
      middleShape,
      materialMiddle,
      false,
      "middle logo",
      "to LightBlue part 1"
    );
    middleAnimations[0].chain(
      ...generateAnimations(
        middleShapeKeyFrameRegrownLightBlue,
        growTime,
        0,
        middleShape,
        materialMiddle,
        false,
        "middle logo",
        "to LightBlue part 2"
      )
    );
    middleAnimations.forEach((animation) => {
      animation.start();
    });
    var bottomAnimations = generateAnimations(
      bottomShapeMinimizedDefault,
      shrinkTime,
      delayBottom,
      bottomShape,
      materialBottom,
      false,
      "bottom logo",
      "to LightBlue part 1"
    );
    bottomAnimations[0].chain(
      ...generateAnimations(
        bottomShapeKeyFrameRegrownLightBlue,
        growTime,
        0,
        bottomShape,
        materialBottom,
        false,
        "bottom logo",
        "to LightBlue part 2"
      )
    );
    bottomAnimations.forEach((animation) => {
      animation.start();
    });
  }
  function animateSwapToMidBlue() {
    container.style.filter = "blur(0px)";
    var topAnimations = generateAnimations(
      topShapeMinimizedDefault,
      shrinkTime,
      delayTop,
      topShape,
      materialTop,
      false,
      "top logo",
      "to MidBlue part 1"
    );
    topAnimations[0].chain(
      ...generateAnimations(
        topShapeKeyFrameRegrownMidBlue,
        growTime,
        0,
        topShape,
        materialTop,
        false,
        "top logo",
        "to MidBlue part 2"
      )
    );
    topAnimations.forEach((animation) => {
      animation.start();
    });
    var middleAnimations = generateAnimations(
      middleShapeMinimizedDefault,
      shrinkTime,
      delayMiddle,
      middleShape,
      materialMiddle,
      false,
      "middle logo",
      "to MidBlue part 1"
    );
    middleAnimations[0].chain(
      ...generateAnimations(
        middleShapeKeyFrameRegrownMidBlue,
        growTime,
        0,
        middleShape,
        materialMiddle,
        false,
        "middle logo",
        "to MidBlue part 2"
      )
    );
    middleAnimations.forEach((animation) => {
      animation.start();
    });
    var bottomAnimations = generateAnimations(
      bottomShapeMinimizedDefault,
      shrinkTime,
      delayBottom,
      bottomShape,
      materialBottom,
      false,
      "bottom logo",
      "to MidBlue part 1"
    );
    bottomAnimations[0].chain(
      ...generateAnimations(
        bottomShapeKeyFrameRegrownMidBlue,
        growTime,
        0,
        bottomShape,
        materialBottom,
        false,
        "bottom logo",
        "to MidBlue part 2"
      )
    );
    bottomAnimations.forEach((animation) => {
      animation.start();
    });
  }
  function animateSwapToDarkBlue() {
    container.style.filter = "blur(0px)";
    var topAnimations = generateAnimations(
      topShapeMinimizedDefault,
      shrinkTime,
      delayTop,
      topShape,
      materialTop,
      false,
      "top logo",
      "to DarkBlue part 1"
    );
    topAnimations[0].chain(
      ...generateAnimations(
        topShapeKeyFrameRegrownDarkBlue,
        growTime,
        0,
        topShape,
        materialTop,
        false,
        "top logo",
        "to DarkBlue part 2"
      )
    );
    topAnimations.forEach((animation) => {
      animation.start();
    });
    var middleAnimations = generateAnimations(
      middleShapeMinimizedDefault,
      shrinkTime,
      delayMiddle,
      middleShape,
      materialMiddle,
      false,
      "middle logo",
      "to DarkBlue part 1"
    );
    middleAnimations[0].chain(
      ...generateAnimations(
        middleShapeKeyFrameRegrownDarkBlue,
        growTime,
        0,
        middleShape,
        materialMiddle,
        false,
        "middle logo",
        "to DarkBlue part 2"
      )
    );
    middleAnimations.forEach((animation) => {
      animation.start();
    });
    var bottomAnimations = generateAnimations(
      bottomShapeMinimizedDefault,
      shrinkTime,
      delayBottom,
      bottomShape,
      materialBottom,
      false,
      "bottom logo",
      "to DarkBlue part 1"
    );
    bottomAnimations[0].chain(
      ...generateAnimations(
        bottomShapeKeyFrameRegrownDarkBlue,
        growTime,
        0,
        bottomShape,
        materialBottom,
        false,
        "bottom logo",
        "to DarkBlue part 2"
      )
    );
    bottomAnimations.forEach((animation) => {
      animation.start();
    });
  }
  function animateFinal() {
    var topAnimations = generateAnimations(
      topShapeMinimizedDefault,
      shrinkTime,
      delayTop,
      topShape,
      materialTop,
      false,
      "top logo",
      "to final part 1"
    );
    topAnimations[0].chain(
      ...generateAnimations(
        topShapeKeyFrameDefault,
        growTime,
        0,
        topShape,
        materialTop,
        false,
        "top logo",
        "to final part 2"
      )
    );
    container.style.filter = "blur(5px)";
    topAnimations.forEach((animation) => {
      animation.start();
    });
    var middleAnimations = generateAnimations(
      middleShapeMinimizedDefault,
      shrinkTime,
      delayMiddle,
      middleShape,
      materialMiddle,
      false,
      "middle logo",
      "to final part 1"
    );
    middleAnimations[0].chain(
      ...generateAnimations(
        middleShapeKeyFrameDefault,
        growTime,
        0,
        middleShape,
        materialMiddle,
        false,
        "middle logo",
        "to final part 2"
      )
    );
    middleAnimations.forEach((animation) => {
      animation.start();
    });
    var bottomAnimations = generateAnimations(
      bottomShapeMinimizedDefault,
      shrinkTime,
      delayBottom,
      bottomShape,
      materialBottom,
      false,
      "bottom logo",
      "to final part 1"
    );
    bottomAnimations[0].chain(
      ...generateAnimations(
        bottomShapeKeyFrameDefault,
        growTime,
        0,
        bottomShape,
        materialBottom,
        false,
        "bottom logo",
        "to final part 2"
      )
    );
    bottomAnimations.forEach((animation) => {
      animation.start();
    });
  }
  function slideInFromSide() {
    const duration = 2 * 1000;
    var topShapeSlideTween = new TWEEN.Tween(topShape.position)
      .to(
        {
          x: topShapeKeyFrameDefault.position.x,
          y: topShapeKeyFrameDefault.position.y,
          z: topShapeKeyFrameDefault.position.z,
        },
        duration
      )
      .easing(TWEEN.Easing.Exponential.Out)
      .delay(400)
      .start();
    var middleShapeSlideTween = new TWEEN.Tween(middleShape.position)
      .to(
        {
          x: middleShapeKeyFrameDefault.position.x,
          y: middleShapeKeyFrameDefault.position.y,
          z: middleShapeKeyFrameDefault.position.z,
        },
        duration
      )
      .easing(TWEEN.Easing.Exponential.Out)
      .delay(200)
      .start();
    var bottomShapeSlideTween = new TWEEN.Tween(bottomShape.position)
      .to(
        {
          x: bottomShapeKeyFrameDefault.position.x,
          y: bottomShapeKeyFrameDefault.position.y,
          z: bottomShapeKeyFrameDefault.position.z,
        },
        duration
      )
      .easing(TWEEN.Easing.Exponential.Out)
      .start();
    initialSlideTweenAnimations.push(topShapeSlideTween, middleShapeSlideTween, bottomShapeSlideTween);
  }
  function setInitialState() {
    linearLogo.rotation.copy(linearLogoKeyFrameDefault.rotation);
    linearLogo.position.copy(linearLogoKeyFrameDefault.position);
    linearLogo.scale.copy(linearLogoKeyFrameDefault.scale);

    topShape.rotation.copy(topShapeKeyFrameOffScreen.rotation);
    topShape.position.copy(topShapeKeyFrameOffScreen.position);
    topShape.scale.copy(topShapeKeyFrameOffScreen.scale);

    middleShape.rotation.copy(middleShapeKeyFrameOffScreen.rotation);
    middleShape.position.copy(middleShapeKeyFrameOffScreen.position);
    middleShape.scale.copy(middleShapeKeyFrameOffScreen.scale);

    bottomShape.rotation.copy(bottomShapeKeyFrameOffScreen.rotation);
    bottomShape.position.copy(bottomShapeKeyFrameOffScreen.position);
    bottomShape.scale.copy(bottomShapeKeyFrameOffScreen.scale);
  }

  animate();
  return { cleanUp };
};
