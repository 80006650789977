import { useState, useEffect } from "react";

const useLayoutHook = () => {
  const [phone, setPhone] = useState(false);

  useEffect(() => {
    const handleLayoutDetection = () => {
      if (window.innerWidth < 1320) setPhone(true);
      else {
        setPhone(false);
      }
    };

    handleLayoutDetection();
    window.addEventListener("resize", handleLayoutDetection);

    return () => {
      window.removeEventListener("resize", handleLayoutDetection);
    };
  }, []);
  return phone;
};

export default useLayoutHook;
