import React, { useEffect, useRef, useState } from "react";
import ThreeEntryPoint from "./ThreeEntryPoint";
import "./style.scss";

const Alogo = () => {
  const ref = useRef();
  const [isPassed, setIsPassed] = useState(false);
  const [translateY, setTranslateY] = useState();
  const [translateX, setTranslateX] = useState(25);
  const [baseOffset, setBaseOffset] = useState();
  const [width, setWidth] = useState();
  const [scrollProgresses, setScrollProgresses] = useState([
    { name: "linearLightBlue", scrollProgress: 0, offSet: null, active: false },
    { name: "linearMidBlue", scrollProgress: 0, offSet: null, active: false },
    { name: "linearDarkBlue", scrollProgress: 0, offSet: null, active: false },
    { name: "linearCenter", scrollProgress: 0, offSet: null, active: false },
  ]);

  const style = {
    transform: isPassed ? `translate(${translateX}px, ${translateY}px)` : `translate(${translateX}px, ${baseOffset}px)`,
  };

  // y1 = Position of Origin div height, used to calculate the relative percentage scroll progress, between y1 and y2
  // y2 = Position of target div height
  // offSet is used to calculate offSet of earlier window.scrollY progress in between each crossover
  const relativePercentageScrollProgress = (y1, y2, offSet) => {
    //Calculates the absolute center position of the origin div.
    let y2Start = y2.y + y2.height / 2 + window.scrollY - offSet;
    //Calculates the center position of the target div on the screen.
    let y2Current = Math.max(y2.y + y2.height / 2, baseOffset);
    //Calculates the absolute center position of the target div.
    let y2End = y1.y + y1.height / 2 + window.scrollY - offSet;
    //Returns the percentage position of the target in between it's Start and End point
    return (y2Current - y2Start) / (y2End - y2Start);
  };

  const calcX = (location, progress) => {
    if (location == "right") {
      return -width / 4 + (width / 4) * (2 * progress);
    } else if (location == "left") {
      return width / 4 - (width / 4) * (2 * progress);
    } else {
      return -width / 4 + (width / 4) * progress;
    }
  };

  const setActive = (list, activeName) => {
    list.forEach((scrollProgress) => {
      scrollProgress.active = scrollProgress.name === activeName;
    });
  };

  useEffect(() => {
    const calculateBaseOffset = () => {
      const offSetTarget = document.getElementsByClassName("home-banner-text")[0].getBoundingClientRect();
      if (offSetTarget) {
        setBaseOffset(offSetTarget.top + window.scrollY + offSetTarget.height / 2 - window.innerHeight / 2);
      }
    };
    if (!baseOffset) calculateBaseOffset();

    const widthTarget = document.getElementsByClassName("home-banner")[0].getBoundingClientRect().width;
    if (widthTarget) {
      setWidth(widthTarget);
      setTranslateX(widthTarget / 4);
    }
    const width = window.innerWidth;
    if (width >= 1320) ThreeEntryPoint(ref.current, scrollProgresses);

    window.addEventListener("resize", calculateBaseOffset);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", calculateBaseOffset);
    };
  }, []);

  useEffect(() => {
    if (baseOffset === undefined || width === undefined) {
      return; // Do not add the listener if baseOffset is not calculated yet
    }

    const handleScroll = () => {
      const startTargetRect = document.getElementsByClassName("home-banner-text")[0].getBoundingClientRect();
      const firstTargetRect = document.getElementsByClassName("secBreak-1")[0].getBoundingClientRect();
      const secondTargetRect = document.getElementsByClassName("secBreak-2")[0].getBoundingClientRect();
      const thirdTargetRect = document.getElementsByClassName("secBreak-3")[0].getBoundingClientRect();
      const lastTargetRect = document.getElementsByClassName("finalBreak")[0].getBoundingClientRect();

      const currentPosition = window.scrollY;

      const updatedScrollProgresses = [...scrollProgresses];

      if (firstTargetRect.y + firstTargetRect.height / 2 >= window.innerHeight / 2 + baseOffset) {
        setActive(updatedScrollProgresses, updatedScrollProgresses[0].name);
        const scrollProg = relativePercentageScrollProgress(startTargetRect, firstTargetRect, 0);
        updatedScrollProgresses[0].scrollProgress = scrollProg;
        setTranslateX(calcX("left", scrollProg));
      } else if (secondTargetRect.y + secondTargetRect.height / 2 >= window.innerHeight / 2 + baseOffset) {
        setActive(updatedScrollProgresses, updatedScrollProgresses[1].name);

        if (!updatedScrollProgresses[1].offSet) {
          updatedScrollProgresses[1].offSet = currentPosition;
        }

        const scrollProg = relativePercentageScrollProgress(
          secondTargetRect,
          thirdTargetRect,
          updatedScrollProgresses[1].offSet
        );

        updatedScrollProgresses[1].scrollProgress = scrollProg;
        setTranslateX(calcX("right", scrollProg));
      } else if (thirdTargetRect.y + thirdTargetRect.height / 2 >= window.innerHeight / 2 + baseOffset) {
        setActive(updatedScrollProgresses, updatedScrollProgresses[2].name);

        if (!updatedScrollProgresses[2].offSet) {
          updatedScrollProgresses[2].offSet = currentPosition;
        }

        const scrollProg = relativePercentageScrollProgress(
          secondTargetRect,
          thirdTargetRect,
          updatedScrollProgresses[2].offSet
        );

        updatedScrollProgresses[2].scrollProgress = scrollProg;
        setTranslateX(calcX("left", scrollProg));
      } else if (lastTargetRect.y + lastTargetRect.height / 2 >= window.innerHeight / 2 + baseOffset) {
        setActive(updatedScrollProgresses, updatedScrollProgresses[3].name);

        if (!updatedScrollProgresses[3].offSet) {
          updatedScrollProgresses[3].offSet = currentPosition;
        }

        const scrollProg = relativePercentageScrollProgress(
          thirdTargetRect,
          lastTargetRect,
          updatedScrollProgresses[3].offSet
        );

        updatedScrollProgresses[3].scrollProgress = scrollProg;
        setTranslateX(calcX("", scrollProg));
      } else {
        setActive(updatedScrollProgresses, "");
        const translateYValue = lastTargetRect.top + lastTargetRect.height / 2 - window.innerHeight / 2;
        if (translateYValue < 0 + baseOffset) {
          setTranslateY(translateYValue);
          setIsPassed(true);
        } else {
          setIsPassed(false);
          setTranslateY(0);
        }
      }

      setScrollProgresses(updatedScrollProgresses);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [baseOffset, scrollProgresses]);

  return <div ref={ref} className="canvas" style={style}></div>;
};

export default Alogo;
