import React, { useState, useEffect } from "react";
import Typography from "../../components/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import Grid from "../Grid";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import PartnersCarousel from "../PartnersCarousel";
import "./style.scss";

const Partners = ({ partners }) => {
  const [slidesConfig, setSlidesConfig] = useState([]);

  useEffect(() => {
    // Define getSlidesConfig inside useEffect or after confirming window is defined
    const getSlidesConfig = () => {
      const screenWidth = window.innerWidth; // Safe to use window here
      if (screenWidth <= 600) {
        return [
          { slidesSize: 5, rows: 1 },
          { slidesSize: 4, rows: 1 },
          { slidesSize: 4, rows: 1 },
        ];
      } else if (screenWidth <= 1200) {
        return [
          { slidesSize: 6, rows: 2 },
          { slidesSize: 6, rows: 2 },
          { slidesSize: 6, rows: 2 },
        ];
      } else {
        return [
          { slidesSize: 10, rows: 2 },
          { slidesSize: 12, rows: 2 },
          { slidesSize: 12, rows: 2 },
        ];
      }
    };

    // Initial set
    setSlidesConfig(getSlidesConfig());

    const handleResize = () => {
      setSlidesConfig(getSlidesConfig()); // Update state on resize
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // The effect depends on nothing and runs only on mount and unmount

  return (
    <Grid container className="pt-154 pb-96 partners" data-aos="fade-down">
      <Grid row justify="center">
        <Typography variant="tittle-2" color="white" component="div" className="mb-40">
          {partners?.title}
        </Typography>
      </Grid>
      <PartnersCarousel
        key={partners?.partnerList[0]?.title}
        title={partners?.partnerList[0]?.title}
        list={partners?.partnerList[0]?.logos}
        slidesSize={slidesConfig[0]?.slidesSize}
        rows={slidesConfig[0]?.rows}
        shouldHaveText={false}
      />
      <PartnersCarousel
        key={partners?.partnerList[1]?.title}
        title={partners?.partnerList[1]?.title}
        list={partners?.partnerList[1]?.logos}
        slidesSize={slidesConfig[1]?.slidesSize}
        rows={slidesConfig[1]?.rows}
        shouldHaveText={false}
      />
      <PartnersCarousel
        key={partners?.partnerList[2]?.title}
        title={partners?.partnerList[2]?.title}
        list={partners?.partnerList[2]?.logos}
        slidesSize={slidesConfig[2]?.slidesSize}
        rows={slidesConfig[2]?.rows}
        shouldHaveText={true}
      />
    </Grid>
  );
};

export default Partners;
