import React, { useState } from "react";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import Typography from "../Typography";
import "./style.scss";

const PartnerLogo = ({ logo, shouldHaveText }) => {
  const [isHovered, setIsHovered] = useState(false);
  const grayscale = isHovered ? { width: "0%", minWidth: 0 } : { width: "100%", minWidth: 80 };
  const colored = isHovered
    ? {
        width: "100%",
        minWidth: 80,
      }
    : { width: "0%", minWidth: 0 };
  const background = isHovered ? { background: "rgba(255, 255, 255, 0.05)", borderRadius: "10px" } : {};
  return (
    <a
      href={logo?.url}
      target="_blank"
      rel="noreferrer"
      className="partners-row-item"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxHeight: 120,
        minWidth: 80,
        justifyContent: "center",
        cursor: "pointer",
        ...background,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <PreviewCompatibleImage
        background
        key={logo?.title + "-colored"}
        imageInfo={{ image: logo?.image, alt: logo?.title }}
        style={{
          // height: "100%",
          maxHeight: 80,
          // minHeight: 43.5,
          minHeight: "15%",
          transitionDuration: 500,
          ...colored,
        }}
        className="partners-row-item-logo"
      />
      <PreviewCompatibleImage
        key={logo?.title + "-gray"}
        imageInfo={{ image: logo?.imageGray, alt: logo?.title }}
        style={{
          // height: "100%",
          maxHeight: 80,
          // minHeight: 43.5,
          minHeight: "15%",
          transitionDuration: 5,
          ...grayscale,
        }}
        className="partners-row-item-logo"
      />
      {shouldHaveText ? <Typography className="logo_name">{logo?.title}</Typography> : null}
    </a>
  );
};

export default PartnerLogo;
