import React, { useState } from "react";
import Typography from "../Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import Grid from "../Grid";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import PropTypes from "prop-types";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import clsx from "clsx";
import "./style.scss";
import PartnerLogo from "../PartnerLogo/PartnerLogo";

SwiperCore.use([Autoplay, Navigation, Pagination]);

const PartnersCarousel = ({ title, list, slidesSize, rows, shouldBeMoving, shouldHaveText }) => {
  const UIShouldBeDisabled = slidesSize >= list.length;

  const sliceAndLoop = (listToSlice, sliceSize) => {
    let content = [];
    for (let i = 0; i < listToSlice.length; i += sliceSize) {
      const slice = listToSlice.slice(i, i + sliceSize);
      const rowSlices = [];
      for (let j = 0; j < slice.length; j += Math.ceil(slice.length / rows)) {
        rowSlices.push(slice.slice(j, j + Math.ceil(slice.length / rows)));
      }
      content.push(
        <SwiperSlide key={title + "-" + i}>
          {rowSlices.map((slice) => {
            return (
              <Grid
                key={slice.key}
                style={{
                  marginTop: "auto",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "0px !important",
                  paddingLeft: "0px !important",
                }}
                row
                justify="center"
                className="partners-row"
              >
                {slice.map((logo) => {
                  return <PartnerLogo logo={logo} shouldHaveText={shouldHaveText} />;
                })}
              </Grid>
            );
          })}
        </SwiperSlide>
      );
    }
    return content;
  };

  return (
    <Grid className={title + " partnerBox"} style={{ height: "120%" }}>
      <Grid row justify="center">
        <Typography variant="tittle-2" color="white" component="div" className="partnerTitle">
          {title}
        </Typography>
      </Grid>
      <hr />
      <Swiper
        {...(UIShouldBeDisabled
          ? {
              autoplay: false,
              watchOverflow: true,
              allowTouchMove: false,
              loop: false,
            }
          : {
              pagination: { clickable: true },
              navigation: true,
              autoplay: {
                delay: 5000,
              },
              loop: true,
            })}
        slidesPerView={1}
      >
        {list ? sliceAndLoop(list, slidesSize) : "null"}
      </Swiper>
    </Grid>
  );
};

PartnersCarousel.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  slidesSize: PropTypes.number,
  rows: PropTypes.number,
  shouldBeMoving: PropTypes.bool,
  shouldHaveText: PropTypes.bool,
};

export default PartnersCarousel;
